.root {
    background-color: var(--mainColor);
    min-height: 100vh;
    padding: 0 1rem 4rem 1rem;
}

.pageContainer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.header {
    padding-top: 1rem;
}

.header h1 {
    margin-top: 0.75rem;
}

.backButton {
    text-decoration: none;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.backButton img {
    height: 15px;
    width: auto;
    transition: 0.3s ease-in-out;
}

.backButton:hover img {
    transform: translateX(-4px);
}

.backButton p {
    color: black;
    font-weight: bold;
}

.containerWaterInfos {
    display: grid;
    gap: 2rem;
}

.containerLastWater {
    background-color: white;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.containerLastWater p {
    text-align: center;
}

.containerTodayWater {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.containerTodayWater button {
    background-color: transparent;
    border: solid 2px var(--secondColor);
    border-radius: 999px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    width: fit-content;
    padding: 0.5rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.containerTodayWater button:hover {
    transform: scale(103%);
}

.containerTodayWater img {
    height: 15px;
    width: auto;
}

.containerWater {
    display: flex;
    align-items: end;
    gap: 0.75rem;
}

.water {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0.5rem 0;
}

.containerGraph {
    background-color: white;
    border-radius: 30px;
    position: relative;
    padding: 2rem 0.5rem 1rem 0.5rem;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.containerGraph > div > div > canvas {
    width: 100%;
}

.containerGraph > div > div > a {
    display: none;
}

.headerGraph {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 3rem;
}

.headerGraph p {
    font-size: 1rem;
    font-weight: bold;
    margin: 0.5rem 0;
}

.containerGraphButtons {
    display: flex;
    gap: 0.5rem;
}

.graphButton {
    background-color: transparent;
    border: 2px solid var(--secondColor);
    border-radius: 999px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: fit-content;
    padding: 0.5rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.graphButton:hover {
    transform: scale(103%);
}

.graphButton img {
    height: 15px;
    width: auto;
}

.loaderMask {
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 30px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    transition: 0.3s ease-in-out;
}

.mask {
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    transition: 0.3s ease-in-out;
}

.containerButtons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 0.5rem 2rem 2rem 2rem;
}

.containerButtons button {
    width: 100%;
}

@media screen and (min-width: 769px) {
    .containerWaterInfos {
        grid-template-columns: repeat(2, 1fr);
    }

    .containerGraph {
        padding: 2rem;
    }

    .headerGraph {
        flex-direction: row;
        justify-content: space-between;
        padding: 0 1rem;
        margin-bottom: 1.5rem;
    }

    .containerButtons button {
        width: unset;
    }
}

@media screen and (min-width: 992px) {
    .root {
        padding: 1.25rem 2rem 5rem 2rem;
    }
}

@media screen and (min-width: 1024px) {
    .root {
        display: flex;
        justify-content: center;
    }

    .pageContainer {
        width: 1024px;
    }
}