.root {
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
}

.header {
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
}

.header h1 {
    margin-bottom: 0.5rem;
}

.headerButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

.headerButtons p {
    font-size: 0.8rem;
    font-weight: bold;
    cursor: pointer;
}

.headerButtons p::after {
    content: "";
    display: block;
    height: 2px;
    background: black;
    transform: scale(0);
    transition: transform 0.3s;
}

.headerButtons p:hover::after {
    transform: scale(1);
}

.backButton {
    width: auto;
    height: 20px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.backButton:hover {
   transform: translateX(-3px);
}

.form {
    background-color: white;
    border-radius: 30px 30px 0 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    margin-top: 2rem;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}

.error {
    background-color: var(--errorBackgroundColor);
    color: var(--errorColor);
    text-align: center;
    border-radius: 999px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 1rem 0.5rem 1rem;
    transition: 0.3s ease-in-out;
}

.error img {
    height: auto;
    width: 13px;
}

.error p {
    font-size: 0.8rem;
}

.formInput {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 0.75rem;
}

.formInput input {
    background-color: var(--lightGrey);
    border: none;
    border-radius: 999px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    padding: 1rem;
}

.formInput input:focus-visible {
    outline: solid 2px var(--mainColor);
}

.formInput input::placeholder {
    color: var(--placeholder);
}

.pwdIcon {
    width: 23px;
    height: auto;
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
}

.formButtonContainer {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.formButtonContainer button {
    width: 100%;
}

@media screen and (min-width: 576px) {
    .error {
        flex-direction: row;
        justify-content: center;
        gap: 0.5rem;
        padding: 0;
    }

    .error p {
        margin: 0.75rem 0;
    }
}

@media screen and (min-width: 992px) {
    .formInput input[type="password"] {
        padding: 1rem 4rem 1rem 1.5rem;
    }
}

@media screen and (min-width: 1024px) {
    .root {
        width: 1024px;
    }
}