.root {
    background-color: white;
    border-radius: 30px 30px 0 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1.25rem 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.link {
    display: flex;
    align-items: center;
}

.icon {
    height: 30px;
    width: auto;
}

@media screen and (min-width: 992px) {
    .icon {
        height: 35px;
        width: auto;
    }
}

@media screen and (min-width: 1024px) {
    .root {
        width: 1024px;
        left: unset;
        right: unset;
    }
}