.list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1.5rem;
}

.listElement {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.listElement div {
    display: flex;
    gap: 0.25rem;
}

.listElement img {
    height: 20px;
    width: auto;
    margin-left: 0.5rem;
    cursor: pointer;
}

.listElement button {
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
}

.date {
    font-weight: bold;
}

.containerArrowButtons {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 1.5rem;
}

.arrowButton {
    background-color: transparent;
    border: 2px solid var(--secondColor);
    border-radius: 999px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    min-width: 40px;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.arrowButton:hover {
    transform: scale(103%);
}

.arrowButton p {
    font-family: "Poppins Regular";
    font-size: 0.85rem;
    font-weight: bold;
    margin: 0;
}

.arrowButton img {
    height: 15px;
    width: auto;
}

.confirmDelete {
    margin-top: 2rem;
}

.confirmDelete div {
    display: flex;
    gap: 0.25rem;
}

.confirmDeleteButtons {
    justify-content: center;
    margin-top: 1rem;
}

.confirmDeleteButtons button {
    min-width: 100px;
    margin-right: 0.25rem;
}

.formInput {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 0.75rem;
}

.formInput input {
    background-color: var(--lightGrey);
    border: none;
    border-radius: 999px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    padding: 1rem;
}

.formInput input:focus-visible {
    outline: solid 2px var(--mainColor);
}

.editModalButtons {
    justify-content: center;
    margin-top: 1rem;
}

.editModalButtons button {
    min-width: 125px;
    margin-right: 0.25rem;
}