.root {
    background-color: white;
    min-height: 100vh;
}

.welcomePage {
    height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;
}

.logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    width: 150px;
    height: auto;
    filter: drop-shadow(5px 0 5px rgba(0, 0, 0, 0.2));
}

.welcomeContainer {
    background-color: var(--mainColor);
    border-radius: 30px 30px 0 0;
    padding: 1rem 1.5rem;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.formContainer {
    background-color: var(--mainColor);
    height: 100%;
    display: grid;
}

.buttonsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin: 2.5rem 0 1.5rem 0;
}

@media screen and (min-width: 992px) {
    .welcomeContainer {
        padding: 1rem 2rem;
    }
}

@media screen and (min-width: 1024px) {
    .welcomePage {
        justify-items: center;
    }

    .welcomeContainer {
        width: 1024px;
    }

    .formContainer {
        justify-items: center;
    }
}