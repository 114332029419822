.root {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}

.root > p {
    color: var(--mainColor);
}

.root button {
    margin-top: 1.5rem;
}

.error {
    background-color: var(--errorBackgroundColor);
    color: var(--errorColor);
    text-align: center;
    border-radius: 999px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 1rem 0.5rem 1rem;
    transition: 0.3s ease-in-out;
}

.error img {
    height: auto;
    width: 13px;
}

.error p {
    font-size: 0.8rem;
}

.formInput {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 0.75rem;
}

.formInput label {
    font-size: 0.85rem;
    font-weight: bold;
    margin: 0 0 0.25rem 1rem;
}

.formInput input {
    background-color: var(--lightGrey);
    border: none;
    border-radius: 999px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    padding: 1rem;
}

.formInput input:focus-visible {
    outline: solid 2px var(--mainColor);
}

@media screen and (min-width: 576px) {
    .error {
        flex-direction: row;
        justify-content: center;
        gap: 0.5rem;
        padding: 0;
    }

    .error p {
        margin: 0.75rem 0;
    }
}