.root {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
}

.root p {
    margin: 0;
}

.important {
    background-color: var(--errorBackgroundColor);
    border-radius: 15px;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem 1rem;
}

.important img {
    width: auto;
    height: 20px;
}

.important p {
    color: var(--errorColor);
    font-size: 0.8rem;
    font-weight: bold;
}

.buttonsContainer {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
}

.buttonsContainer button {
    min-width: 125px;
}

.error {
    background-color: var(--errorBackgroundColor);
    color: var(--errorColor);
    text-align: center;
    border-radius: 999px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 1rem 0.5rem 1rem;
    transition: 0.3s ease-in-out;
}

.error img {
    height: auto;
    width: 13px;
}

.error p {
    font-size: 0.8rem;
}

@media screen and (min-width: 576px) {
    .error {
        flex-direction: row;
        justify-content: center;
        gap: 0.5rem;
        padding: 0;
    }

    .error p {
        margin: 0.75rem 0;
    }
}