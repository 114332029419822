.root {
    background-color: rgba(0, 0, 0, 0.45);
    position: fixed;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    opacity: 100%;
    z-index: 5;
    transition: 0.3s ease-in-out;
}

.rootHidden {
    position: fixed;
    opacity: 0;
    background-color: transparent;
    transition: 0.3s ease-in-out;
}

.container {
    background-color: white;
    border-radius: 30px 30px 0 0;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 2rem;
    z-index: 2;
    transition: 0.3s ease-in-out;
}

.containerHidden {
    background-color: white;
    border-radius: 30px 30px 0 0;
    position: relative;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 2rem;
    transform: translateY(100vh);
    transition: 0.3s ease-in-out;
}

.header {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
}

.header h2 {
    font-size: 1.1rem;
    margin: 0;
}

.header img {
    height: 25px;
    width: auto;
    cursor: pointer;
}

.mask {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
}

.maskHidden {
    display: none;
}

@media screen and (min-width: 769px) {
    .root {
        display: flex;
        justify-content: center;
    }
}

@media screen and (min-width: 1024px) {
    .container {
        width: 1024px;
        left: unset;
        right: unset;
    }
    
    .containerHidden {
        width: 1024px;
        left: unset;
        right: unset;
    }
}