.popup {
    background-color: white;
    border: none;
    border-radius: 15px;
    max-width: 175px;
    position: fixed;
    top: 1rem;
    right: 1rem;
    padding: 0.25rem 1rem;
    opacity: 0;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    transition: opacity 0.3s ease-in-out;
}

.popupContent {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.popupContent img {
    height: 20px;
    width: auto;
}

.popupContent p {
    font-size: 0.8rem;
}

.visible {
    opacity: 1;
}