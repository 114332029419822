.root {
    background-color: var(--mainColor);
    min-height: 100vh;
    padding: 1rem 1rem 4rem 1rem;
}

.header {
    padding-bottom: 1rem;
}

.toggleContainer {
    background-color: white;
    border-radius: 30px;
    padding: 1rem 1.5rem;
    margin-bottom: 2rem;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.toggleHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
}

.toggleHeader p {
    font-size: 1rem;
    font-weight: bold;
}

.toggleHeader img {
    width: auto;
    height: 20px;
    cursor: pointer;
}

.toggleContent {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.toggleContentOpen {
    max-height: 2500px;
    transition: max-height 0.3s ease;
}

.toggleContent ol {
    font-size: 0.9rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding-left: 1.25rem;
}

.toggleContent a {
    color: var(--secondColor);
}

.bold {
    font-weight: bold;
}

@media screen and (min-width: 992px) {
    .root {
        padding: 1.25rem 2rem 5rem 2rem;
    }
}

@media screen and (min-width: 1024px) {
    .root {
        display: flex;
        justify-content: center;
    }

    .pageContainer {
        width: 1024px;
    }
}