.root {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
}

.root p {
    margin: 0;
}

.error {
    background-color: var(--errorBackgroundColor);
    color: var(--errorColor);
    text-align: center;
    border-radius: 999px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 1rem 0.5rem 1rem;
    transition: 0.3s ease-in-out;
}

.error img {
    height: auto;
    width: 13px;
}

.error p {
    font-size: 0.8rem;
}

.firstInput {
    display: grid;
    gap: 1rem;
}

.formInput {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 0.75rem;
}

.formInput input {
    background-color: var(--lightGrey);
    border: none;
    border-radius: 999px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    padding: 1rem;
}

.formInput input:focus-visible {
    outline: solid 2px var(--mainColor);
}

.pwdIcon {
    width: 23px;
    height: auto;
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
}

.passwordError {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-left: 1.5rem;
}

.passwordError img {
    height: 13px;
    width: auto;
    margin-top: 0.75rem;
}

.passwordError p {
    color: var(--errorColor);
    font-size: 0.8rem;
    margin-bottom: 0;
}

.buttonsContainer {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1.5rem;
}

.buttonsContainer button {
    min-width: 125px;
}

@media screen and (min-width: 576px) {
    .error {
        flex-direction: row;
        justify-content: center;
        gap: 0.5rem;
        padding: 0;
    }

    .error p {
        margin: 0.75rem 0;
    }
}

@media screen and (min-width: 769px) {
    .firstInput {
        grid-template-columns: 1fr 1fr;
        gap: 0.5rem;
    }
}

@media screen and (min-width: 992px) {
    .formInput input[type="password"] {
        padding: 1rem 4rem 1rem 1.5rem;
    }
}